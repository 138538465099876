import './TypeAheadResults.scss';
import React, { Component } from 'react';


export default class TypeAheadResults extends Component{
  _timeoutID;


  //Lifecycle
  constructor(props){
    super(props);

    this.state = {
      isManagingFocus: false
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  //Events
  handleClickOutside(event){
    if(this.wrapperRef && !this.wrapperRef.contains(event.target)){
      this.props.onClickOutside();
    }
  }
  onBlur(){
    this._timeoutID = setTimeout(() => {
      if(this.state.isManagingFocus){
        this.setState({
          isManagingFocus: false
        }, () => {
          // console.log('lost focus');
          this.props.onClickOutside();
          // if(this.wrapperRef && !this.wrapperRef.contains(event.target)){
          //   this.props.onClickOutside();
          // }
        });
      }
    }, 0);
  }
  onFocus(){
    clearTimeout(this._timeoutID);
    if(!this.state.isManagingFocus){
      // console.log('gained focus');
      this.setState({
        isManagingFocus: true
      });
    }
  }


  //Functions
  setWrapperRef(node){
    this.wrapperRef = node;
  }


  //Rendering
  render(){
    console.log(this.props);
    if(this.props.results.length < 1) return null;

    const keySelectedClass = result => result.key == this.props.keySelected ? 'typeahead-results-result--key-selected' : '';

    return (
      <div className="typeahead-results"
           ref={this.setWrapperRef}>
        {this.props.results.map(result => (
          <div className={`typeahead-results-result ${keySelectedClass(result)}`}
               key={result.key}
               onBlur={this.onBlur}
               onClick={() => this.props.onClick(result)}
               onFocus={this.onFocus}>{result.label}</div>
        ))}
      </div>
    );
  };
}
