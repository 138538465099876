import axios from 'axios';


export const modeStyles = {
  WALK: {
    paint: {
      'line-color': '#3BAE6C',
      'line-width': 5,
      'line-dasharray': [.5, 2]
    }
  },
  BUS: {
    paint: {
      'line-color': '#F58245',
      'line-width': 4
    }
  },
  SUBWAY: {
    paint: {
      'line-color': '#000000',
      'line-width': 4
    }
  },
  TRAM: {
    paint: {
      'line-color': '#000000',
      'line-width': 4
    }
  },
  RAIL: {
    paint: {
      'line-color': '#000000',
      'line-width': 4
    }
  },
  TRANSIT: {
    paint: {
      'line-color': '#000000',
      'line-width': 4
    }
  }
};
export const bus_replaceIf000000 = '#CF1F3F';
export const mapStyle = 'mapbox://styles/mapbox/streets-v11';
export const api_devsar_base = process.env.REACT_APP_DEVSAR_BASE;
export const api_devsar_rating = `${process.env.REACT_APP_DEVSAR_BASE}rating`;
export const api_devsar_ngData = `${process.env.REACT_APP_DEVSAR_BASE}csv`;
export const api_typeahead_base = `${process.env.REACT_APP_TYPEAHEAD_BASE}search`;
export const typeahead_query = query => new Promise((resolve, reject) => {
  if(query.length > 2){
    axios.get(api_typeahead_base, {
        params: {
          ignoreLoadingBar: true,
          size: 10,
          text: query
        }
      })
      .then(response => {
        if(response.statusText == 'OK'){
          resolve(response.data.features.map(feature => ({
            key: feature.properties.id,
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0],
            label: feature.properties.label
          })));
        }
        else reject(response);
      })
      .catch(function(error){
        console.error(query, error);
        reject(error);
      });
  }
  else resolve([]);
});

export const capitalizeFirstLetter = function(s){
  if(typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
