import './PageStart.scss';
import * as common from '../../utils/common';
import axios from 'axios';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Map from '../Map/Map';
import moment from 'moment';
import RateIntro from '../RateIntro/RateIntro';
import React from 'react';
import Survey from '../Survey/Survey';
import TripPlan from '../TripPlan/TripPlan';
import TripResult from '../TripResult/TripResult';
import update from 'react-addons-update';


export default class PageStart extends React.Component{
  //Lifecycle
  constructor(props){
    super(props);
    this.state = {
      currentPane: 'RateIntro',
      tripPlan: {
        to: 'a',
        from: 'b',
        time: '8:08 PM',
        comparison: {}
      },
      isMobileMapMode: false,
      isPageStructureRightVisible: true,
      isCurrentTrip: true
    };
    this.pageStructureRight = React.createRef();
    console.log(process.env);
  }
  componentDidMount(){
    this.setState({
      isPageStructureRightVisible: this.pageStructureRight.current && !this.isHidden(this.pageStructureRight.current),
      tookSurvey: localStorage.getItem('tookSurvey')
    });
    window.addEventListener('resize', this.window_onResize);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.window_onResize);
  }


  //Events
  mapClose_onClick = () => this.setState({isMobileMapMode: false});
  mapIt_onClick = () => {
    // console.log('ps mioc');
    this.setState({isMobileMapMode: true});
  };
  rateIt_onClick = rating => {
    if(!this.state.tookSurvey)
      this.setState({
        currentPane: 'Survey',
        nextGenRating: rating
      });
    else
      window.location = '/thank-you/rated-only';
  };
  rateIntroRate_onClick = () => this.setState({currentPane: 'TripPlan'});
  tripPlan_onSubmit = results => new Promise((resolve, reject) => {
    this.setState({
      currentPane: 'TripResult',
      tripPlan: {
        from: results.fromStr,
        to: results.toStr,
        time: results.timeStr,
        date: results.dateSelected,
        dateStr: results.dateStr
      }
    }, () => {
      let date = moment(this.state.tripPlan.date).format('MM-DD-YYYY');
      let fromLatitude = results.fromSelected.latitude;
      let fromLongitude = results.fromSelected.longitude;
      let time = moment(`1/1/2020 ${results.timeStr}`).format('hh:mma');
      console.log(`1/1/2020 ${results.timeStr}`, time);
      let toLatitude = results.toSelected.latitude;
      let toLongitude = results.toSelected.longitude;
      let userId = localStorage.getItem('userId');

      axios.get(common.api_devsar_base, {
        params: {
          arriveBy: false,
          date: date,
          fromPlace: `${fromLatitude},${fromLongitude}`,
          maxWalkDistance: 2253.0759999999996,
          mode: `TRANSIT,WALK`,
          numItineraries: 2,
          optimize: `QUICK`,
          showIntermediateStops: true,
          time: time,
          toPlace: `${toLatitude},${toLongitude}`,
          wheelchair: false,
          userId: userId
        }
      }).then(result => {
        if(result.statusText == 'OK'){
          localStorage.setItem('userId', result.data.userId);
          this.setState({
            tripPlan: update(this.state.tripPlan, {
              comparison: {$set: result.data}
            })
          }, resolve);
        }
        else this.setState({
          tripPlan: update(this.state.tripPlan, {
            comparison: {$set: {error: 'Could not connect to trip service. Please try again later.', tryAgainError: true}}
          })
        }, resolve);
      }, () => {
        this.setState({
          tripPlan: update(this.state.tripPlan, {
            comparison: {$set: {error: 'Could not connect to trip service. Please try again later.', tryAgainError: true}}
          })
        }, resolve);
      });
    });
  });
  window_onResize = () => {
    // console.log(this.pageStructureRight);
    this.setState({
      isPageStructureRightVisible: this.pageStructureRight.current && !this.isHidden(this.pageStructureRight.current)
    });
  };
  isCurrentTrip_onChange = (isCurrentTrip, callback) => {
    this.setState({
      isCurrentTrip
    }, callback);
  };


  //Functions
  paneClass = paneName => this.state.currentPane == paneName ? 'pane pane--is-open' : 'pane';
  isHidden = (el) => window.getComputedStyle(el).display === 'none';


  //Rendering
  renderBody(){
    if(this.state.currentPane == 'Survey'){
      let apiUserId = localStorage.getItem('userId');
      return (
        <Survey apiRequestId={this.state.tripPlan.comparison.requestID}
                apiUserId={apiUserId}
                nextGenRating={this.state.nextGenRating}/>
      );
    }
    else
      return (
        <div className="page-start__structure-columns">
          <div className="page-start__structure-left">
            <RateIntro className={this.paneClass('RateIntro')}
                       rate_onClick={this.rateIntroRate_onClick}/>
            <TripPlan className={this.paneClass('TripPlan')}
                      form_onSubmit={this.tripPlan_onSubmit}/>
            <TripResult className={this.paneClass('TripResult')}
                        tripPlan={this.state.tripPlan}
                        mapIt_onClick={this.mapIt_onClick}
                        rateIt_onClick={this.rateIt_onClick}
                        isCurrentTrip={this.state.isCurrentTrip}
                        isShowingDirections={this.state.isPageStructureRightVisible}/>
          </div>
          <div className="page-start__structure-right"
               ref={this.pageStructureRight}>
            {this.renderMap()}
          </div>
        </div>
      );
  }
  renderFooter(){
    if(this.state.currentPane != 'RateIntro') return null;

    return (<Footer/>);
  }
  renderMap(){
    if(!this.state.isMobileMapMode &&
       !this.state.isPageStructureRightVisible) return null;

    return (
      <Map tripPlan={this.state.tripPlan}
           isMobileMapMode={this.state.isMobileMapMode}
           mapClose_onClick={this.mapClose_onClick}
           isCurrentTrip={this.state.isCurrentTrip}
           isCurrentTrip_onChange={this.isCurrentTrip_onChange}/>
    );
  }
  render(){
    // console.log(`PageStart.render()`, this.props, this.state.isPageStructureRightVisible);

    const mapItClass = this.state.isMobileMapMode ? 'page-start--mobile-map' : '';
    // const mapItClass = this.state.isMobileMapMode ? 'page__structure-body--mobile-map' : '';

    return (
      <div className={`page page-start ${mapItClass}`}>
        <div className="page__structure-container">
          <Header/>
          <div className='page__structure-body'>
            {this.renderBody()}
          </div>
          {this.renderFooter()}
        </div>
      </div>
    );
  };
}
