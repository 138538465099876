import React from 'react';
import './RateIntro.scss';


export default function RateIntro(props){
  // console.log(`RateIntro`, props);
  const render = () => {
    return (
      <div className={`rate-intro ${props.className || ''}`}>
        <div className="rate-intro__content">
          <div className="rate-intro__text">Metro&apos;s new NextGen bus service means less waiting, shorter travel times and improved weekend service.</div>
          <button className="rate-intro__rate tg-button tg-button--white"
                  onClick={props.rate_onClick}>Rate your NextGen trip
          </button>
        </div>
      </div>
    );
  };
  return render();
}
