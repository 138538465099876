import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie11';

import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import PageStart from './components/PageStart/PageStart';
import './App.scss';
import PageThankYou from './components/PageThankYou/PageThankYou';
import PageNgData from './components/PageNgData/PageNgData';


class App extends Component{
  render(){
    return (
      <React.Fragment>
        <Route exact path="/thank-you/:ratedOnly" component={PageThankYou}/>
        <Route exact path="/thank-you" component={PageThankYou}/>
        <Route exact path="/ng-data" component={PageNgData}/>
        <Route exact path="/" component={PageStart}/>
      </React.Fragment>
    );
  }
}

export default App;
