import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './PageNgData.scss';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import DatePicker from 'react-datepicker/es';
import axios from 'axios';
import * as common from '../../utils/common';
import update from 'react-addons-update';
import FileSaver from 'file-saver';


export default class PageNgData extends React.Component{
  //Lifecycle
  constructor(props){
    super(props);
    let d = new Date();
    this.state = {
      dateEndError: null,
      dateEndSelected: isMobile ? moment().format('YYYY-MM-DD') : d,
      dateEndStr: moment().format('YYYY-MM-DD'),
      dateStartError: null,
      dateStartSelected: isMobile ? moment().format('YYYY-MM-DD') : d,
      dateStartStr: moment().format('YYYY-MM-DD'),
      detailSelected: false
    };
  }


  //Events
  dateStart_onChange = e => {
    //console.log(e);
    this.setState({
      dateEndError: moment(e).isAfter(moment(this.state.dateEndSelected)) ? 'Please select an End Date after the Start Date.' : null,
      dateStartError: moment(e).isAfter(moment(this.state.dateEndSelected)) ? 'Please select a Start Date before the End Date.' : null,
      dateStartSelected: e
    });
  };
  dateStart_onChange_input = e => {
    let dateStartSelected = moment(e.target.value).toDate();
    this.setState({
      dateEndError: moment(e.target.value).isAfter(moment(this.state.dateEndSelected)) ? 'Please select an End Date after the Start Date.' : null,
      dateStartError: moment(e.target.value).isAfter(moment(this.state.dateEndSelected)) ? 'Please select a Start Date before the End Date.' : null,
      dateStartSelected: dateStartSelected,
      dateStartStr: moment(e.target.value).format('YYYY-MM-DD')
    });
  };
  dateEnd_onChange = e => {
    this.setState({
      dateEndError: moment(this.state.dateStartSelected).isAfter(moment(e)) ? 'Please select an End Date after the Start Date.' : null,
      dateEndSelected: e,
      dateStartError: moment(this.state.dateStartSelected).isAfter(moment(e)) ? 'Please select a Start Date before the End Date.' : null
    });
  };
  dateEnd_onChange_input = e => {
    let dateEndSelected = moment(e.target.value).toDate();
    this.setState({
      dateEndError: moment(this.state.dateStartSelected).isAfter(moment(e.target.value)) ? 'Please select an End Date after the Start Date.' : null,
      dateEndSelected: dateEndSelected,
      dateEndStr: moment(e.target.value).format('YYYY-MM-DD'),
      dateStartError: moment(this.state.dateStartSelected).isAfter(moment(e.target.value)) ? 'Please select a Start Date before the End Date.' : null
    });
  };
  download_onClick = () => {
    if(moment(this.state.dateStartSelected).isAfter(moment(this.state.dateEndSelected)) && !moment(this.state.dateStartSelected).isSame(moment(this.state.dateEndSelected))){
      console.log('is after', moment(this.state.dateStartSelected).isSame(moment(this.state.dateEndSelected)), this.state.dateStartSelected, this.state.dateEndSelected);
      return;
    }

    console.log({
      date_from: moment(this.state.dateStartSelected).format('YYYY-MM-DD'),
      date_to: moment(this.state.dateEndSelected).format('YYYY-MM-DD'),
      detail: this.state.detailSelected
    });
    axios.get(common.api_devsar_ngData, {
      headers: {
        'csv-key': process.env.REACT_APP_DEVSAR_NGDATA
      },
      params: {
        date_from: moment(this.state.dateStartSelected).format('YYYY-MM-DD'),
        date_to: moment(this.state.dateEndSelected).format('YYYY-MM-DD'),
        detail: this.state.detailSelected
      }
    }).then(result => {
      let headerLine = result.headers['content-disposition'];
      let filename = headerLine.substr(headerLine.indexOf('filename=') + 9);
      if(result.statusText == 'OK'){
        this.setState({
          dateStartError: null,
          dateEndError: null,
          inProgress: false
        });
        var blob = new Blob([result.data], {type: 'text/csv;charset=utf-8'});
        FileSaver.saveAs(blob, filename);
      }
      else this.setState({
        dateStartError: null,
        dateEndError: null,
        inProgress: false,
        resultError: 'Could not connect to csv service. Please try again later.'
      });
    }, () => {
      this.setState({
        dateStartError: null,
        dateEndError: null,
        inProgress: false,
        resultError: 'Could not connect to csv service. Please try again later.'
      });
    });
  };
  detailSelected_onChange = () => this.setState({detailSelected: !this.state.detailSelected});


  //Rendering
  render(){
    // console.log(`PageNgData.render()`, this.props);

    return (
      <div className={`page page-ng-data`}>
        <div className="page__structure-container">
          <Header/>
          <div className='page__structure-body'>
            <div className="page-ng-data__content">
              <div className="page-ng-data__title">NextGen Feedback Download Tool</div>

              <div className="cols cols--plan">
                <div className="cols__col cols__col--ng-data-left">
                  <label className="tg-label" htmlFor="dateStart">Select<br/>Start Date</label>
                </div>
                <div className="cols__col cols__col--plan-right">
                  {!isMobile &&
                   <DatePicker className={`page-ng-data__input tg-input tg-input--text ${this.state.dateStartError ? 'tg-input--error' : ''}`}
                               id="dateStart"
                               onChange={this.dateStart_onChange}
                               selected={this.state.dateStartSelected}/>
                  }
                  {isMobile &&
                   <input className={`page-ng-data__input tg-input tg-input--text ${this.state.dateStartError ? 'tg-input--error' : ''}`}
                          id="dateStart"
                          onChange={this.dateStart_onChange_input}
                          type="date"
                          value={this.state.dateStartStr}/>
                  }
                </div>
              </div>
              {this.state.dateStartError &&
               <div className="cols cols--plan">
                 <div className="cols__col cols__col--ng-data-left">
                 </div>
                 <div className="cols__col cols__col--plan-right">
                   <div className="trip-plan__form-error">
                     {this.state.dateStartError}
                   </div>
                 </div>
               </div>
              }


              <div className="cols cols--plan">
                <div className="cols__col cols__col--ng-data-left">
                  <label className="tg-label" htmlFor="dateEnd">Select<br/>End Date</label>
                </div>
                <div className="cols__col cols__col--plan-right">
                  {!isMobile &&
                   <DatePicker className={`page-ng-data__input tg-input tg-input--text ${this.state.dateEndError ? 'tg-input--error' : ''}`}
                               id="dateEnd"
                               onChange={this.dateEnd_onChange}
                               selected={this.state.dateEndSelected}/>
                  }
                  {isMobile &&
                   <input className={`page-ng-data__input tg-input tg-input--text ${this.state.dateEndError ? 'tg-input--error' : ''}`}
                          id="dateEnd"
                          onChange={this.dateEnd_onChange_input}
                          type="date"
                          value={this.state.dateEndStr}/>
                  }
                </div>
              </div>
              {this.state.dateEndError &&
               <div className="cols cols--plan">
                 <div className="cols__col cols__col--ng-data-left">
                 </div>
                 <div className="cols__col cols__col--plan-right">
                   <div className="trip-plan__form-error">
                     {this.state.dateEndError}
                   </div>
                 </div>
               </div>
              }


              <div className="cols cols--plan">
                <div className="cols__col cols__col--ng-data-left"></div>
                <div className="cols__col cols__col--plan-right">
                  <select className={`page-ng-data__input tg-input tg-input--select ${this.state.timeError ? 'tg-input--error' : ''}`}
                          id="detailSelected"
                          onChange={this.detailSelected_onChange}
                          value={this.state.detailSelected}>
                    <option value={false}>User Data</option>
                    <option value={true}>User and Development Data</option>
                  </select>
                </div>
              </div>


              {this.state.inProgress &&
               <div className="trip-plan__form-waiting">
                 Please wait...
               </div>
              }
              <div className="cols cols--plan">
                <div className="cols__col cols__col--ng-data-left"/>
                <div className="cols__col cols__col--plan-right">
                  <button className="trip-plan__plan-it tg-button tg-button--red"
                          disabled={this.state.inProgress || !this.state.dateStartSelected || !this.state.dateEndSelected || this.state.dateStartError || this.state.dateEndError}
                          onClick={this.download_onClick}>Download
                  </button>
                  {this.state.resultError &&
                   <div className="page-ng-data__error">{this.state.resultError}</div>
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    );
  };
}
