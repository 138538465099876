import './Map.scss';
import * as common from '../../utils/common';
import Directions from '../Directions/Directions';
import EventEmitter from 'eventemitter3';
import iconPinA from '../../assets/icon-pin-a.svg';
import iconPinB from '../../assets/icon-pin-b.svg';
import iconXRed from '../../assets/icon-x-red.svg';
import React, { Component } from 'react';
import ReactMapGL, { Layer, Marker, Popup, Source } from 'react-map-gl';
import Swipe from 'react-easy-swipe';
import update from 'react-addons-update';
import WebMercatorViewport from 'viewport-mercator-project';


export default class Map extends Component{
  lastSwipePosition;


  //Lifecycle
  constructor(props){
    super(props);
    this.mapInfoRef = React.createRef();
    this.mapContainerRef = React.createRef();
    this.mapDirectionsRef = React.createRef();
    this.state = {
      isCurrentTrip: true,
      viewport: {
        longitude: -118.2331113,
        latitude: 34.0563869,
        zoom: 11,
        mapStyle: common.mapStyle
      },
      legStepsOpen: [],
      isMapFocused: true,
      computedLines: {}
    };
    this.viewportZoom_onChange = this.viewportZoom_onChange.bind(this);
    this.eventEmitter = new EventEmitter();
  }
  componentDidMount(){
    if(this.props.tripPlan.comparison.currentTrip && this.props.tripPlan.comparison.currentTrip.result)
      this.trip_set(true);
    else if(this.props.tripPlan.comparison.nextGenTrip && this.props.tripPlan.comparison.nextGenTrip.result)
      this.trip_set(false);
  }
  componentDidUpdate(prevProps){
    if(prevProps.tripPlan.comparison !== this.props.tripPlan.comparison){
      this.trip_set(true);
    }
  }
  componentWillUnmount(){
    this.eventEmitter.removeListener('MAP_LOADED');
  }
  // static getDerivedStateFromProps(props, current_state) {
  //   if (current_state.value !== props.value) {
  //     return {
  //       value: props.value,
  //       computed_prop: heavy_computation(props.value)
  //     }
  //   }
  //   return null
  // }


  //Events
  viewportZoom_onChange(event){
    // console.log(this);
    this.setState({
      viewport: {
        ...this.state.viewport,
        zoom: parseInt(event.target.value < 1 ? 1 : event.target.value > 24 ? 24 : event.target.value)
      }
    });
  }
  currentTrip_onClick = () => {
    this.trip_set(true);
  };
  nextGenTrip_onClick = () => {
    this.trip_set(false);
  };
  directions_onSwipeStart = () => {
  };
  directions_onSwipeMove = (position, event) => {
    this.lastSwipePosition = position;
  };
  directions_onSwipeEnd = () => {
    if(this.state.isMapFocused || this.mapInfoRef.current.scrollTop < 1)
      this.setState({isMapFocused: this.lastSwipePosition ? this.lastSwipePosition.y > 0 : true});
  };
  directions_onScroll = () => {
    // console.log(this.mapInfoRef.current.scrollHeight, this.mapDirectionsRef.current.scrollHeight);
    if(this.mapInfoRef.current.clientHeight < this.mapInfoRef.current.scrollHeight)
      this.setState({isMapFocused: this.mapInfoRef.current.scrollTop < 1});
  };
  reactMap_onLoad = () => {
    // console.log('map loaded');
    this.setState({isMapLoaded: true}, () => this.eventEmitter.emit('MAP_LOADED', true));
  };


  //Variables
  Markers = React.memo(({markers}) => (markers || []).map(
    marker => <Marker {...marker}>
      <div className="marker-icon"
           onClick={() => {
             // console.log(marker);
             this.setState({
               popups: this.state.popups ? this.state.popups.map(p => ({
                 ...p,
                 show: !!(p.latitude == marker.latitude && p.longitude == marker.longitude)
               })) : null
             });
           }}>
        {true && <div className="marker-icon marker-icon--dot"></div>}
        {marker.isPinA && <img className="marker-icon marker-icon--pin"
                               src={iconPinA}/>}
        {marker.isPinB && <img className="marker-icon marker-icon--pin"
                               src={iconPinB}/>}
      </div>
    </Marker>
  ));
  // Popups = React.memo(({popups}) => (popups || []).map(
  SourceMarkers = (<Source type="geojson" data={{
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-118.347141, 34.004763]
        },
        properties: {
          'marker-color': '#3bb2d0',
          'marker-size': 'large',
          'marker-symbol': 'rocket'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-118.3965577, 33.9600725]
        },
        properties: {
          'marker-color': '#3bb2d0',
          'marker-size': 'large',
          'marker-symbol': 'rocket'
        }
      }
    ]
  }}>
    <Layer {...{
      id: 'data',
      type: 'point',
      paint: {
        'fill-color': {
          property: 'percentile',
          stops: [
            [0, '#3288bd'],
            [1, '#66c2a5'],
            [2, '#abdda4'],
            [3, '#e6f598'],
            [4, '#ffffbf'],
            [5, '#fee08b'],
            [6, '#fdae61'],
            [7, '#f46d43'],
            [8, '#d53e4f']
          ]
        },
        'fill-opacity': 0.8
      }
    }} />
  </Source>);


  //Functions
  getFitBoundsRange = markers => ([[
    markers.sort((a, b) => a.longitude - b.longitude)[0].longitude,
    markers.sort((a, b) => a.latitude - b.latitude)[0].latitude
  ], [
    markers.sort((a, b) => b.longitude - a.longitude)[0].longitude,
    markers.sort((a, b) => b.latitude - a.latitude)[0].latitude
  ]]);
  setUpMap = tripData => {
    console.log(`showing ${this.trip_getName()}`, tripData);

    //create lines
    let computedLines = tripData.legs.map((leg, i) => {
      let modeStyle = common.modeStyles[leg.mode];
      let coordinates = this.decodePolyLine(leg.legGeometry.points).map(pt => [pt.longitude, pt.latitude]);
      let paint = modeStyle.paint;
      switch(leg.mode){
        case 'BUS':
          if(leg.routeColor)
            paint = {
              ...modeStyle.paint,
              'line-color': leg.routeColor == '000000' ? common.bus_replaceIf000000 : `#${leg.routeColor}`
            };
          break;
        default:
          if(leg.routeColor)
            paint = {
              ...modeStyle.paint,
              'line-color': `#${leg.routeColor}`
            };
      }
      return {
        coordinates,
        paint
      };
    });

    console.log(computedLines);
    this.setState({
      computedLines: update(this.state.computedLines, {
        [this.trip_getName()]: {$set: computedLines}
      })
    });

    let lines = [];
    computedLines.forEach((computedLine, computedLine_i) => {
      // computedLine.coordinates.forEach((coords, coords_i) => {
      lines.push({
        'id': `tgleg_${this.trip_getName()}_${computedLine_i}`,
        'type': 'line',
        'source': {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'LineString',
              'coordinates': computedLine.coordinates
            }
          }
        },
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': computedLine.paint
      });
      // })
    });
    // console.log(`lines: `, lines);


    //create markers
    let stopMarkers = [];
    tripData.legs.forEach((leg, i) => {
      let coordinates = [];
      switch(leg.mode){
        case 'WALK':
          coordinates = leg.steps;
          break;
        default:
          coordinates = leg.intermediateStops;
      }
      stopMarkers.push(
        ...coordinates.map((c, ci) => ({
          anchor: 'center',
          className: 'maboxgl-marker--stop',
          key: `marker_stop_${i}_${ci}`,
          latitude: c.lat,
          longitude: c.lon,
          name: c.name,
          // text: `${i}`,
          isDot: true
        }))
      );
    });
    let markers = [
      //Leg From
      ...tripData.legs.map((leg, i) => ({
        anchor: 'bottom',
        className: i == 0 ? 'mapboxgl-marker--start' : 'maboxgl-marker--from',
        key: `marker_from_${i}`,
        latitude: leg.from.lat,
        longitude: leg.from.lon,
        name: leg.from.name,
        // text: `From ${i}`,
        isPinA: i == 0,
        isDot: i > 0
      })),
      //Leg To
      ...tripData.legs.map((leg, i) => ({
        anchor: 'bottom',
        className: i == tripData.legs.length - 1 ? 'mapboxgl-marker--end' : 'maboxgl-marker--to',
        key: `marker_to_${i}`,
        latitude: leg.to.lat,
        longitude: leg.to.lon,
        name: leg.to.name,
        // text: `To ${i}`,
        isPinB: i == tripData.legs.length - 1,
        isDot: i < tripData.legs.length - 1
      })),
      //Leg Steps / Stops
      ...stopMarkers
    ].filter(marker => marker.name && marker.name.length > 0);
    // console.log(`markers: `, markers);


    //create popups
    let popups = markers
      .map(marker => ({
        key: `popup_${marker.key}`,
        latitude: marker.latitude,
        longitude: marker.longitude,
        text: marker.name,
        show: false
      }));
    // console.log('popups', popups);


    const whenLoaded = () => {
      //get map
      const map = this.reactMap.getMap();

      //fit bounds
      let boundsRange = this.getFitBoundsRange(markers);
      // console.log(boundsRange);


      //fit bounds 2.0
      // construct a viewport instance from the current state
      console.log(this.mapContainerRef.current && this.mapContainerRef.current.clientHeight);
      const viewport = new WebMercatorViewport(this.state.viewport);
      const {longitude, latitude, zoom} = viewport.fitBounds([
        [boundsRange[0][0], boundsRange[0][1]],
        [boundsRange[1][0], boundsRange[1][1]]
      ], {
        padding: this.mapContainerRef.current ? this.mapContainerRef.current.clientHeight * .1 : 0
      });

      //remove previous layers
      if(this.state.isDoneWithFirstLoad)
        map.getStyle().layers
          .filter(layer => layer.id.indexOf('tgleg') >= 0)
          .forEach(layer => {
            map.removeLayer(layer.id);
            map.removeSource(layer.id);
          });

      //add new layers
      if(map._loaded)
        lines.forEach(layer => map.addLayer(layer));
      else
        map.on('load', lines.forEach(layer => map.addLayer(layer)));

      this.setState({
        markers,
        popups,
        viewport: {
          ...this.state.viewport,
          longitude,
          latitude,
          zoom
        },
        isDoneWithFirstLoad: true
      }, () => {
      });

    };

    if(this.state.isMapLoaded) whenLoaded();
    else this.eventEmitter.on('MAP_LOADED', whenLoaded);
  };
  trip_set = isCurrent => {
    // console.log('trip_set', isCurrent);
    if(this.props.tripPlan.comparison && (
      (isCurrent && this.props.tripPlan.comparison.currentTrip && this.props.tripPlan.comparison.currentTrip.result) ||
      (!isCurrent && this.props.tripPlan.comparison.nextGenTrip && this.props.tripPlan.comparison.nextGenTrip.result)
    )){
      this.props.isCurrentTrip_onChange(
        isCurrent,
        () => this.setState(
          {
            legStepsOpen: []
          }, () => this.setUpMap(this.trip_getCurrent()))
      );
    }
    else{
      console.error(`isCurrent: ${isCurrent}. This trip doesn't have result data.`, this.props.tripPlan.comparison);
    }
  };
  trip_getCurrent = () => this.props.tripPlan.comparison[this.trip_getName()].result;
  trip_getName = () => this.props.isCurrentTrip ? 'currentTrip' : 'nextGenTrip';

  /* eslint-disable no-unused-expressions */
  decodePolyLine = (t, e) => {
    for(
      var n,
        o,
        u = 0,
        l = 0,
        r = 0,
        d = [],
        h = 0,
        i = 0,
        a = null,
        c = Math.pow(10, e || 5);
      u < t.length;
    ){
      (a = null), (h = 0), (i = 0);
      do (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
      while(a >= 32);
      (n = 1 & i ? ~(i >> 1) : i >> 1), (h = i = 0);
      do (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
      while(a >= 32);
      (o = 1 & i ? ~(i >> 1) : i >> 1),
        (l += n),
        (r += o),
        d.push([l / c, r / c]);
    }
    return (d = d.map(function(t){
      return {latitude: t[0], longitude: t[1]};
    }));
  };


  //Rendering
  renderPopups = popups => (popups || []).map(
    popup => (<Popup {...popup}
                     className={popup.show ? 'popup popup--show' : 'popup'}
                     closeButton={true}
                     closeOnClick={true}
                     onClose={() => {
                       popup.show = false;
                       this.setState({popups});
                     }}
                     anchor="bottom">
      <div className="popup-text">{popup.text}</div>
    </Popup>)
  );
  renderDirections(){
    if(!this.props.tripPlan.comparison ||
       !this.props.tripPlan.comparison.currentTrip ||
       !this.props.tripPlan.comparison.currentTrip.result) return null;

    return (<Directions currentTrip={this.trip_getCurrent()}/>);
  };
  renderTripSelect(){
    if(!this.props.tripPlan ||
       !this.props.tripPlan.comparison ||
       !this.props.tripPlan.comparison.currentTrip ||
       !this.props.tripPlan.comparison.nextGenTrip ||
       (!this.props.tripPlan.comparison.currentTrip.result && !this.props.tripPlan.comparison.nextGenTrip.result)) return null;

    const twoButtonsLeftClass = this.props.isCurrentTrip ? 'tg-button--two-buttons--is-active' : '';
    const twoButtonsRightClass = !this.props.isCurrentTrip ? 'tg-button--two-buttons--is-active' : '';

    return (
      <div className="cols cols--two-buttons">
        {this.props.tripPlan.comparison.currentTrip.result && (
          <div className="cols__col cols__col--half">
            <button className={`tg-button tg-button--two-buttons ${twoButtonsLeftClass}`}
                    onClick={this.currentTrip_onClick}
                    disabled={this.props.isCurrentTrip}>Current Trip
            </button>
          </div>
        )}
        {this.props.tripPlan.comparison.nextGenTrip.result && (
          <div className="cols__col cols__col--half">
            <button className={`tg-button tg-button--two-buttons ${twoButtonsRightClass}`}
                    onClick={this.nextGenTrip_onClick}
                    disabled={!this.props.isCurrentTrip}>NextGen Trip
            </button>
          </div>
        )}
      </div>
    );
  }
  render(){
    // console.log(`Map.render()`, this.state.viewport);

    const mapItClass = this.props.isMobileMapMode ? 'map--mobile-map' : '';
    const focusClass = this.state.isMapFocused ? 'map--mobile-map-focus-map' : 'map--mobile-map-focus-directions';

    return (
      <div className={`map ${mapItClass} ${focusClass}`}>
        <div className="map__react-map-container" ref={this.mapContainerRef}>
          <ReactMapGL {...this.state.viewport}
                      onClick={() => {
                        this.setState({
                          popups: this.state.popups ? this.state.popups.map(p => ({
                            ...p,
                            show: false
                          })) : null
                        });
                      }}
                      onLoad={this.reactMap_onLoad}
                      onViewportChange={(viewport) => this.setState({viewport: {...viewport, mapStyle: common.mapStyle}})}
                      ref={(reactMap) => this.reactMap = reactMap}>
            {/*<this.SourceMarkers/>*/}
            <this.Markers markers={this.state.markers}/>
            {this.renderPopups(this.state.popups)}
          </ReactMapGL>
        </div>
        <div className="map__close"
             onClick={this.props.mapClose_onClick}>
          <img src={iconXRed} alt="Close" className="page-start__map-close-icon"/>
        </div>
        <div className="map__info"
             onScroll={this.directions_onScroll}
             ref={this.mapInfoRef}>
          <Swipe onSwipeStart={this.directions_onSwipeStart}
                 onSwipeMove={this.directions_onSwipeMove}
                 onSwipeEnd={this.directions_onSwipeEnd}>
            <div ref={this.mapDirectionsRef}>
              {this.renderDirections()}
            </div>
          </Swipe>
        </div>
        {this.renderTripSelect()}
      </div>
    );
  };
}
