import './TripPlan.scss';
import * as common from '../../utils/common';
import moment from 'moment';
import React from 'react';
import TypeAheadInput from '../TypeAheadInput/TypeAheadInput';
import { isMobile } from 'react-device-detect';
import DatePicker from 'react-datepicker/es';
import 'react-datepicker/dist/react-datepicker.css';


const DATE_CHOICES = {weekdays: 'weekdays', saturday: 'saturday', sunday: 'sunday'};

export default class TripPlan extends React.Component{
  //Lifecycle
  constructor(props){
    super(props);

    let currentHour = parseInt(moment(Date.now()).format('H'));
    let currentMinutes = parseInt(moment(Date.now()).format('mm'));
    let targetMinutes = currentMinutes > 30 ? '00' : '30';
    let targetHour = targetMinutes == '00' ? (currentHour == 23 ? 0 : currentHour + 1) : currentHour;

    this.state = {
      dateError: null,
      dateStr: DATE_CHOICES.weekdays,
      dateSelected: isMobile ? moment().format('YYYY-MM-DD') : new Date(),
      fromError: null,
      fromSelected: null,
      fromStr: '',
      timeStr: isMobile ? moment().format('HH:mm') : `${targetHour}:${targetMinutes}`,
      toError: null,
      toSelected: null,
      toStr: ''
    };
  }


  //Events
  date_onBlur = () => setTimeout(() => this.setState({dateError: this.state.dateSelected ? null : 'Please select a date.'}), 100);
  date_onChange = e => {
    let dateSelected;
    let today = moment();
    switch(e.target.value){
      case DATE_CHOICES.weekdays:
        dateSelected = moment().startOf('week').add(1, 'day');
        break;
      case DATE_CHOICES.saturday:
        dateSelected = moment().endOf('week');
        break;
      case DATE_CHOICES.sunday:
        dateSelected = moment().startOf('week');
        break;
    }
    if(today.isSameOrAfter(dateSelected, 'day'))
      dateSelected = dateSelected.add(1, 'week');

    // console.log(e.target.value);
    // console.log(dateSelected, new Date());
    this.setState({
      dateSelected: dateSelected.toDate(),
      dateStr: e.target.value
    });
  };
  form_onSubmit = e => {
    e.preventDefault();


    let formPromises = [];
    formPromises.push(new Promise((resolve, reject) => {
      if(!!this.state.fromSelected) resolve(this.state.fromSelected);
      else if(this.state.fromStr && this.state.fromStr.length < 1) resolve(null);
      else common.typeahead_query(this.state.fromStr).then(results => {
          if(!results) this.setState({
            fromError: `The Start address could not be found. Please check spelling or try a different address.`,
            inProgress: false
          });
          else if(results.length > 0) resolve(results[0]);
          else resolve(null);
        }, error => {
          this.setState({
            fromError: `The Start address could not be found. Please check spelling or try a different address.`,
            inProgress: false
          });
        });
    }));
    formPromises.push(new Promise((resolve, reject) => {
      //console.log(this.state.toSelected, this.state.toStr);
      if(!!this.state.toSelected) resolve(this.state.toSelected);
      else if(this.state.toStr && this.state.toStr.length < 1) resolve(null);
      else common.typeahead_query(this.state.toStr).then(results => {
          if(!results) this.setState({
            toError: `The End address could not be found. Please check spelling or try a different address.`,
            inProgress: false
          });
          else if(results.length > 0) resolve(results[0]);
          else resolve(null);
        }, error =>
          this.setState({
            toError: `The End address could not be found. Please check spelling or try a different address.`,
            inProgress: false
          }));
    }));
    formPromises.push(new Promise((resolve, reject) => {
      if(this.state.timeStr.length > 1 && moment(`1/1/2020 ${this.state.timeStr}`).isValid()) resolve(this.state.timeStr);
      else resolve(null);
    }));
    formPromises.push(new Promise((resolve, reject) => {
      if(!this.state.dateSelected) resolve(this.state.dateSelected);
      else resolve(null);
    }));

    let callback = () => {
      return Promise.all(formPromises).then(([from, to, time, date]) => {
          let fromStr = from ? from.label : this.state.fromStr;
          let toStr = to ? to.label : this.state.toStr;

          this.setState({
            dateError: null,
            fromError: null,
            fromSelected: from,
            fromStr,
            toError: null,
            toSelected: to,
            toStr
          }, () => {
            if(!(from && to && time))
              this.setState({
                dateError: date ? null : 'Please select a date.',
                fromError: from ? null : 'The Start address could not be found. Please check spelling or try a different address.',
                inProgress: false,
                timeError: time ? null : 'Please select a time.',
                toError: to ? null : 'The End address could not be found. Please check spelling or try a different address.'
              });
            else{
              this.setState({
                inProgress: false
              }, () => {
                this.props.form_onSubmit(this.state).then(
                  () => this.setState({inProgress: false}),
                  console.error
                );
              });
            }
          });
        })
        .catch(console.error);
    };

    if(!this.state.fromSelected || !this.state.toSelected)
      this.setState({
        fromError: null,
        toError: null,
        inProgress: true
      }, callback);
    else callback();
  };
  from_onBlur = () => setTimeout(() => this.setState({
    fromError: this.state.fromSelected ? null : 'The Start address could not be found. Please check spelling or try a different address.'
  }), 100);
  from_onChange = result => this.setState({fromStr: result});
  from_onSelect = result => this.setState({
    fromError: result ? null : this.state.fromError,
    fromSelected: result
  });
  time_onBlur = () => setTimeout(() => this.setState({timeError: this.state.timeStr && moment(`1/1/2020 ${this.state.timeStr}`).isValid() ? null : 'Please select a time.'}), 100);
  time_onChange = e => {
    //console.log(e.target.value);
    this.setState({timeStr: e.target.value});
  };
  to_onBlur = () => setTimeout(() => this.setState({
    toError: this.state.toSelected ? null : 'The End address could not be found. Please check spelling or try a different address.'
  }), 100);
  to_onChange = result => this.setState({toStr: result});
  to_onSelect = result => this.setState({
    toError: result ? null : this.state.toError,
    toSelected: result
  });


  //Functions


  //Rendering
  render(){
    return (
      <div className={`trip-plan ${this.props.className || ''}`}>
        <div className="trip-plan__content">
          <div className="trip-plan__title">Which transit trip do you take most often?</div>

          <form onSubmit={this.form_onSubmit}>
            <div className="cols cols--plan">
              <div className="cols__col cols__col--plan-left">
                <label className="tg-label" htmlFor="from">Start</label>
              </div>
              <div className="trip-plan__typeahead-parent cols__col cols__col--plan-right">
                <TypeAheadInput id="from"
                                input_onBlur={this.from_onBlur}
                                input_className={`trip-plan__input tg-input tg-input--text ${this.state.fromError ? 'tg-input--error' : ''}`}
                                onChange={result => this.from_onChange(result)}
                                onSelect={result => this.from_onSelect(result)}/>
              </div>
            </div>
            {this.state.fromError &&
             <div className="cols cols--plan">
               <div className="cols__col cols__col--plan-left">
               </div>
               <div className="cols__col cols__col--plan-right">
                 <div className="trip-plan__form-error">
                   {this.state.fromError}
                 </div>
               </div>
             </div>
            }

            <div className="cols cols--plan">
              <div className="cols__col cols__col--plan-left">
                <label className="tg-label" htmlFor="to">End</label>
              </div>
              <div className="trip-plan__typeahead-parent cols__col cols__col--plan-right">
                <TypeAheadInput id="to"
                                input_onBlur={this.to_onBlur}
                                input_className={`trip-plan__input tg-input tg-input--text ${this.state.toError ? 'tg-input--error' : ''}`}
                                onChange={result => this.to_onChange(result)}
                                onSelect={result => this.to_onSelect(result)}/>
              </div>
            </div>
            {this.state.toError &&
             <div className="cols cols--plan">
               <div className="cols__col cols__col--plan-left">
               </div>
               <div className="cols__col cols__col--plan-right">
                 <div className="trip-plan__form-error">
                   {this.state.toError}
                 </div>
               </div>
             </div>
            }

            <div className="cols cols--plan">
              <div className="cols__col cols__col--plan-left">
                <label className="tg-label" htmlFor="time">Time</label>
              </div>
              <div className="cols__col cols__col--plan-right">
                {isMobile &&
                 <input className={`trip-plan__input tg-input tg-input--text ${this.state.timeError ? 'tg-input--error' : ''}`}
                        id="time"
                        onBlur={this.time_onBlur}
                        onChange={this.time_onChange}
                        type="time"
                        value={this.state.timeStr}/>
                }
                {!isMobile &&
                 <select className={`trip-plan__input tg-input tg-input--select ${this.state.timeError ? 'tg-input--error' : ''}`}
                         id="time"
                         onBlur={this.time_onBlur}
                         onChange={this.time_onChange}
                         value={this.state.timeStr}>
                   <option value="">Select a time</option>
                   <option value="0:00">12:00 AM</option>
                   <option value="0:30">12:30 AM</option>
                   <option value="1:00">1:00 AM</option>
                   <option value="1:30">1:30 AM</option>
                   <option value="2:00">2:00 AM</option>
                   <option value="2:30">2:30 AM</option>
                   <option value="3:00">3:00 AM</option>
                   <option value="3:30">3:30 AM</option>
                   <option value="4:00">4:00 AM</option>
                   <option value="4:30">4:30 AM</option>
                   <option value="5:00">5:00 AM</option>
                   <option value="5:30">5:30 AM</option>
                   <option value="6:00">6:00 AM</option>
                   <option value="6:30">6:30 AM</option>
                   <option value="7:00">7:00 AM</option>
                   <option value="7:30">7:30 AM</option>
                   <option value="8:00">8:00 AM</option>
                   <option value="8:30">8:30 AM</option>
                   <option value="9:00">9:00 AM</option>
                   <option value="9:30">9:30 AM</option>
                   <option value="10:00">10:00 AM</option>
                   <option value="10:30">10:30 AM</option>
                   <option value="11:00">11:00 AM</option>
                   <option value="11:30">11:30 AM</option>
                   <option value="12:00">12:00 PM</option>
                   <option value="12:30">12:30 PM</option>
                   <option value="13:00">1:00 PM</option>
                   <option value="13:30">1:30 PM</option>
                   <option value="14:00">2:00 PM</option>
                   <option value="14:30">2:30 PM</option>
                   <option value="15:00">3:00 PM</option>
                   <option value="15:30">3:30 PM</option>
                   <option value="16:00">4:00 PM</option>
                   <option value="16:30">4:30 PM</option>
                   <option value="17:00">5:00 PM</option>
                   <option value="17:30">5:30 PM</option>
                   <option value="18:00">6:00 PM</option>
                   <option value="18:30">6:30 PM</option>
                   <option value="19:00">7:00 PM</option>
                   <option value="19:30">7:30 PM</option>
                   <option value="20:00">8:00 PM</option>
                   <option value="20:30">8:30 PM</option>
                   <option value="21:00">9:00 PM</option>
                   <option value="21:30">9:30 PM</option>
                   <option value="22:00">10:00 PM</option>
                   <option value="22:30">10:30 PM</option>
                   <option value="23:00">11:00 PM</option>
                   <option value="23:30">11:30 PM</option>
                 </select>
                }
              </div>
            </div>
            {this.state.timeError &&
             <div className="cols cols--plan">
               <div className="cols__col cols__col--plan-left">
               </div>
               <div className="cols__col cols__col--plan-right">
                 <div className="trip-plan__form-error">
                   {this.state.timeError}
                 </div>
               </div>
             </div>
            }

            <div className="cols cols--plan">
              <div className="cols__col cols__col--plan-left">
                <label className="tg-label" htmlFor="date">Date</label>
              </div>
              <div className="cols__col cols__col--plan-right">
                <select className={`trip-plan__input tg-input tg-input--select ${this.state.timeError ? 'tg-input--error' : ''}`}
                        id="date"
                        onBlur={this.date_onBlur}
                        onChange={this.date_onChange}
                        value={this.state.dateStr}>
                  <option value={DATE_CHOICES.weekdays}>Weekdays (M-F)</option>
                  <option value={DATE_CHOICES.saturday}>Saturday</option>
                  <option value={DATE_CHOICES.sunday}>Sunday</option>
                </select>
              </div>
            </div>
            {this.state.dateError &&
             <div className="cols cols--plan">
               <div className="cols__col cols__col--plan-left">
               </div>
               <div className="cols__col cols__col--plan-right">
                 <div className="trip-plan__form-error">
                   {this.state.dateError}
                 </div>
               </div>
             </div>
            }


            {this.state.inProgress &&
             <div className="trip-plan__form-waiting">
               Checking addresses, please wait...
             </div>
            }
            <div className="cols cols--plan">
              <div className="cols__col cols__col--plan-left"/>
              <div className="cols__col cols__col--plan-right">
                <button className="trip-plan__plan-it tg-button tg-button--red"
                        disabled={this.state.inProgress || !this.state.dateSelected || !this.state.fromSelected || !this.state.toSelected || !this.state.timeStr}>Plan it
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
}
