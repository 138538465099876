import './TripResult.scss';
import * as common from '../../utils/common';
import axios from 'axios';
import CountUp from 'react-countup';
import Directions from '../Directions/Directions';
import iconRateIt1 from '../../assets/icon-rate-1.svg';
import iconRateIt2 from '../../assets/icon-rate-2.svg';
import iconRateIt3 from '../../assets/icon-rate-3.svg';
import iconRateIt4 from '../../assets/icon-rate-4.svg';
import iconRateIt5 from '../../assets/icon-rate-5.svg';
import moment from 'moment';
import React, { Component } from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import { StickyContainer, Sticky } from 'react-sticky';


export default class TripResult extends Component{
  //Lifecycle
  constructor(props){
    super(props);
    this.state = {
      nextGenRating: -1,
      isRatingAvailable: true
    };
    this.tripResultTitleRef = React.createRef();
  }


  //Events
  nextGenRating_onChange = e => {
    this.setState({
      isRatingAvailable: true,
      nextGenRating: e
    });
  };
  rateIt_onClick = () => {
    axios.post(common.api_devsar_rating, {
      rating: this.state.nextGenRating,
      requestID: this.props.tripPlan.comparison.requestID
    });
    this.setState({isRatingAvailable: false});
    this.props.rateIt_onClick(this.state.nextGenRating);
  };


  //Functions
  trip_getCurrent = () => this.props.tripPlan.comparison[this.trip_getName()].result;
  trip_getName = () => this.props.isCurrentTrip ? 'currentTrip' : 'nextGenTrip';


  //Rendering
  renderDirections(){
    if(!this.props.tripPlan.comparison ||
       !this.props.tripPlan.comparison.currentTrip ||
       !this.props.tripPlan.comparison.currentTrip.result ||
       !this.props.isShowingDirections) return null;

    return (<Directions currentTrip={this.trip_getCurrent()}/>);
  };
  renderStat(statName, statTitle, tripData, showMins){
    if(!(tripData.currentTrip.result && tripData.current[statName] > 0) &&
       !(tripData.nextGenTrip.result && tripData.nextgen[statName] > 0)) return null;

    return (
      <React.Fragment>
        <div className="trip-result__comparison-details-title">{statTitle}</div>
        <div className="trip-result__comparison-details-info cols">
          {tripData.currentTrip.result && (
            <div className="trip-result__col-divider cols__col cols__col--half">
              <div className="trip-result__comparison-details-time">
                {/*<CountUp duration={6} end={tripData.current[statName]}/>*/}
                {tripData.current[statName]}
                {showMins && <span className="trip-result__comparison-details-time-unit">min</span>}
              </div>
            </div>
          )}
          {tripData.nextGenTrip.result && (
            <div className="cols__col cols__col--half">
              <div className="trip-result__comparison-details-time color-red">
                <CountUp duration={3} end={tripData.nextgen[statName]}/>
                {showMins && <span className="trip-result__comparison-details-time-unit color-red">min</span>}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
  renderTripComparison(){
    const tripData = {
      ...this.props.tripPlan.comparison
    };

    if(!this.props.tripPlan ||
       !this.props.tripPlan.comparison)
      return (
        <div className="trip-result__comparison">
          <div className="trip-result__calculating">
            <div className="trip-result__calculating-spinner tg-spinner"/>
            <div className="trip-result__calculating-text">Calculating trip, please wait...</div>
          </div>
        </div>
      );

    if(tripData.tryAgainError) return (
      <div className="trip-result__comparison">
        <div className="trip-result__error trip-result__error--no-title">
          <div className="trip-result__error-body">
            Could not connect to trip service. <a href="/">Please try again.</a>
          </div>
        </div>
      </div>
    );

    if(tripData.error) return (
      <div className="trip-result__comparison">
        <div className="trip-result__error">
          <div className="trip-result__error-title">No trip found.</div>
          <div className="trip-result__error-body">
            Sorry, we can't find that trip. <a href="/">Please try another.</a>
          </div>
        </div>
      </div>
    );

    if((!tripData.currentTrip || !tripData.currentTrip.result) &&
       (!tripData.nextGenTrip || !tripData.nextGenTrip.result)) return (
      <div className="trip-result__comparison">
        <div className="trip-result__error">
          <div className="trip-result__error-title">No routes available. Please try a different trip.</div>
        </div>
      </div>
    );


    if(tripData.currentTrip.result){
      tripData.currentLegFirst = tripData.currentTrip.result.legs[0];
      tripData.currentLegLast = tripData.currentTrip.result.legs[tripData.currentTrip.result.legs.length - 1];
      tripData.current = {
        totalTripTime: Math.round(tripData.currentTrip.result.total_duration / 60),
        nextArrivalTime: Math.round(tripData.currentTrip.result.nextArrivalTime / 60),
        walkToBusStop: Math.round(tripData.currentTrip.result.walkToStart / 60),
        totalTransfers: tripData.currentTrip.result.transfers,
        numberOfStops: tripData.currentTrip.result.numberOfStops,
        walkToDestination: Math.round(tripData.currentTrip.result.walkToEnd / 60)
      };
    }
    if(tripData.nextGenTrip.result){
      tripData.nextGenLegFirst = tripData.nextGenTrip.result.legs[0];
      tripData.nextGenLegLast = tripData.nextGenTrip.result.legs[tripData.nextGenTrip.result.legs.length - 1];
      tripData.nextgen = {
        totalTripTime: Math.round(tripData.nextGenTrip.result.total_duration / 60),
        nextArrivalTime: Math.round(tripData.nextGenTrip.result.nextArrivalTime / 60),
        walkToBusStop: Math.round(tripData.nextGenTrip.result.walkToStart / 60),
        totalTransfers: tripData.nextGenTrip.result.transfers,
        numberOfStops: tripData.nextGenTrip.result.numberOfStops,
        walkToDestination: Math.round(tripData.nextGenTrip.result.walkToEnd / 60)
      };
    }

    const comparisonClass = !tripData.currentTrip.result ? 'trip-result__comparison--nextgen-only' :
                            !tripData.nextGenTrip.result ? 'trip-result__comparison--current-only' : '';

    return (
      <div className={`trip-result__comparison ${comparisonClass}`}>
        {(!tripData.currentTrip.result || !tripData.nextGenTrip.result) && (
          <div className="trip-result__comparison-missing">
            {!tripData.currentTrip.result && (
              <div className="trip-result__comparison-missing-text">{tripData.currentTrip.msg}</div>
            )}
            {!tripData.nextGenTrip.result && (
              <div className="trip-result__comparison-missing-text">{tripData.nextGenTrip.msg}</div>
            )}
          </div>
        )}
        <div className="trip-result__stats">
          <div className="trip-result__comparison-bgs">
            {tripData.currentTrip.result && (
              <div className="trip-result__comparison-bg trip-result__comparison-bg--current"/>
            )}
            {tripData.nextGenTrip.result && (
              <div className="trip-result__comparison-bg trip-result__comparison-bg--nextgen"/>
            )}
          </div>
          <div className="trip-result__comparison-content">
            <div className="trip-result__comparison-titles">
              <div className="cols">
                {tripData.currentTrip.result && (
                  <div className="cols__col cols__col--half">
                    <div className="trip-result__comparison-titles-title">Current</div>
                  </div>
                )}
                {tripData.nextGenTrip.result && (
                  <div className="cols__col cols__col--half">
                    <div className="trip-result__comparison-titles-title">NextGen</div>
                  </div>
                )}
              </div>
            </div>
            <div className="trip-result__comparison-totals">
              <div className="trip-result__comparison-totals-title">Total Trip Time</div>
              <div className="cols">
                {tripData.currentTrip.result && (
                  <div className="trip-result__col-vs cols__col cols__col--half">
                    <React.Fragment>
                      <div className="trip-result__comparison-totals-time">
                        {/*<CountUp duration={6} end={tripData.current.totalTripTime}/>*/}
                        {tripData.current.totalTripTime}
                      </div>
                      <div className='trip-result__comparison-totals-unit'>minutes</div>
                    </React.Fragment>
                  </div>
                )}
                {tripData.nextGenTrip.result && (
                  <div className="cols__col cols__col--half">
                    <React.Fragment>
                      <div className="trip-result__comparison-totals-time color-red">
                        <CountUp duration={3} end={tripData.nextgen.totalTripTime}/>
                      </div>
                      <div className='trip-result__comparison-totals-unit color-red'>minutes</div>
                    </React.Fragment>
                  </div>
                )}
              </div>
            </div>
            <div className="trip-result__comparison-details">
              {this.renderStat('nextArrivalTime', 'Next Arrival Time', tripData, true)}
              {this.renderStat('walkToBusStop', 'Walk to Start', tripData, true)}
              {this.renderStat('walkToDestination', 'Walk to End', tripData, true)}
              {this.renderStat('totalTransfers', 'Total Transfers', tripData)}
              {this.renderStat('numberOfStops', 'Number of Stops', tripData)}
            </div>
            <button className="trip-result__map-it tg-button tg-button--map-it"
                    onClick={this.props.mapIt_onClick}>Map it
            </button>
          </div>
        </div>
      </div>
    );
  };
  renderRateIt(){
    if(!this.props.tripPlan ||
       !this.props.tripPlan.comparison ||
       !this.props.tripPlan.comparison.currentTrip ||
       !this.props.tripPlan.comparison.currentTrip.result ||
       !this.props.tripPlan.comparison.nextGenTrip ||
       !this.props.tripPlan.comparison.nextGenTrip.result) return null;

    return (
      <div className="trip-result__rate-it">
        <div className="trip-result__rate-it-title">How do you like your NextGen trip?</div>
        <RadioGroup className="trip-result__rate-it-group"
                    name="rate-it"
                    onChange={this.nextGenRating_onChange}
                    selectedValue={this.state.nextGenRating}>
          <Radio className="trip-result__rate-it-radio"
                 id="rate-it_1"
                 name="rate-it_1"
                 value="1"/>
          <label className="trip-result__rate-it-label" htmlFor="rate-it_1">
            <img alt="1" src={iconRateIt1}/>
          </label>
          <Radio className="trip-result__rate-it-radio"
                 id="rate-it_2"
                 name="rate-it_2"
                 value="2"/>
          <label className="trip-result__rate-it-label" htmlFor="rate-it_2">
            <img alt="2" src={iconRateIt2}/>
          </label>
          <Radio className="trip-result__rate-it-radio"
                 id="rate-it_3"
                 name="rate-it_3"
                 value="3"/>
          <label className="trip-result__rate-it-label" htmlFor="rate-it_3">
            <img alt="3" src={iconRateIt3}/>
          </label>
          <Radio className="trip-result__rate-it-radio"
                 id="rate-it_4"
                 name="rate-it_4"
                 value="4"/>
          <label className="trip-result__rate-it-label" htmlFor="rate-it_4">
            <img alt="4" src={iconRateIt4}/>
          </label>
          <Radio className="trip-result__rate-it-radio"
                 id="rate-it_5"
                 name="rate-it_5"
                 value="5"/>
          <label className="trip-result__rate-it-label" htmlFor="rate-it_5">
            <img alt="5" src={iconRateIt5}/>
          </label>
        </RadioGroup>
        <button className="trip-result__rate-it-button tg-button tg-button--red"
                onClick={this.rateIt_onClick}
                disabled={this.state.nextGenRating < 1 || !this.state.isRatingAvailable}>{this.state.isRatingAvailable ? 'Rate it' : 'Thank you!'}
        </button>
      </div>
    );
  };
  render(){
    // console.log(`TripResult.render()`, this.props);
    let titleHeight = this.tripResultTitleRef.current ? this.tripResultTitleRef.current.clientHeight : 0;

    return (
      <StickyContainer className={`trip-result ${this.props.className || ''}`}>
        <div className="trip-result__title" ref={this.tripResultTitleRef}>Compare your current and NextGen trips and then rate.</div>
        <Sticky relative={this.props.isShowingDirections}
                topOffset={this.props.isShowingDirections ? 0 : titleHeight}>
          {({
              style
            }) => (
            <div className="trip-result__query" style={style}>
              <div className="cols cols--result">
                <div className="cols__col cols__col--result-left">
                  <label className="tg-label" htmlFor="from">Start</label>
                </div>
                <div className="cols__col cols__col--result-right">
                  {this.props.tripPlan.from}
                </div>
              </div>
              <div className="cols cols--result">
                <div className="cols__col cols__col--result-left">
                  <label className="tg-label" htmlFor="to">End</label>
                </div>
                <div className="cols__col cols__col--result-right">
                  {this.props.tripPlan.to}
                </div>
              </div>
              <div className="cols cols--result">
                <div className="cols__col cols__col--result-left">
                  <label className="tg-label" htmlFor="time">Time</label>
                </div>
                <div className="cols__col cols__col--result-right">
                  {moment(`1/1/2010 ${this.props.tripPlan.time}`).format('LT')}
                </div>
              </div>
              <div className="cols cols--result">
                <div className="cols__col cols__col--result-left">
                  <label className="tg-label" htmlFor="time">Date</label>
                </div>
                <div className="cols__col cols__col--result-right">
                  {common.capitalizeFirstLetter(this.props.tripPlan.dateStr)}
                </div>
              </div>
            </div>
          )}
        </Sticky>
        {this.renderTripComparison()}
        {this.renderDirections()}
        {this.renderRateIt()}
      </StickyContainer>
    );
  };
}
