import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './PageThankYou.scss';


export default class PageThankYou extends React.Component{
  //Lifecycle
  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidMount(){
    localStorage.setItem("tookSurvey", true);
  }


  //Events
  rateAnother_onClick = () => {
    this.props.history.push('/');
  };


  //Rendering
  render(){
    // console.log(`PageThankYou.render()`, this.props);

    return (
      <div className={`page page-thank-you`}>
        <div className="page__structure-container">
          <Header/>
          <div className='page__structure-body'>
            <div className="page-thank-you__content">
              <div className="page-thank-you__title">Thanks for riding and helping us make your system better.</div>
              {!this.props.match.params.ratedOnly &&
                <div className="page-thank-you__sub-title">If you entered your email address, you have been entered into the contest.</div>
              }
              <button className="page-thank-you__rate-another tg-button tg-button--red"
                      onClick={this.rateAnother_onClick}>Rate another NextGen trip
              </button>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    );
  };
}
